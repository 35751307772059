<template>
  <div>
    <div class="banner">
      <warning-tip code="SPSKUCOUNT"></warning-tip>

      <main-top
        title="商品SKU占比分析"
        desc="在线查询天猫、淘宝商品宝贝SKU"
        spend="SPSKUCOUNT"
      >
        <!-- 使用搜索框组件 -->
        <InputView
            :extractProductId="true"
            :CanYouClick="CanYouClick"
            placeholder-name="请输入您的宝贝链接或宝贝ID"
            @sendSearchName="getSearchName"
          ></InputView>
      </main-top>

      <bordure-row title="商品信息" shadow style="margin-bottom: 30px">
        <div class="bannerContent" style="padding-bottom: 20px">
          <div class="img_floor" v-if="goodsEntity[0].picUrl == ''"></div>
          <img
            v-else
            :src="goodsEntity[0].picUrl"
            style="dispaly: block; width: 100px; height: 100px"
            alt="图片加载中"
          />
          <div style="margin-left: 16px">
            <p
              style="
                font-size: 14px;
                color: #333333;
                letter-spacing: 0;
                margin: 6px 0;
              "
            >
              {{ goodsEntity[0].goodsName }}
            </p>
            <p
              style="
                font-size: 12px;
                color: #666666;
                letter-spacing: 0;
                margin-bottom: 16px;
              "
            >
              {{ goodsEntity[0].nick }}
            </p>
            <p style="font-size: 14px; color: #333333; letter-spacing: 0">
              {{ goodsEntity[0].price }}
            </p>
          </div>
        </div>
        <!-- 以下部分为sku饼图 -->
        <div
          style="
            min-height: 600px;
            border: 1px solid #e5e5e5;
            padding: 20px 15px;
            border-radius: 2px;
          "
          ref="echarts"
        ></div>
      </bordure-row>

      <!-- 讲解文案区域 -->
      <Introduce :introduce="introduce"></Introduce>
    </div>
  </div>
</template>

<script>
import { getGoodsFeedList, goodsSkuCount } from "../../request/api"; //这里是引入请求
import InputView from "../public/InputView";
import Introduce from "../public/Introduce";
export default {
  data() {
    return {
      value: "",
      CanYouClick: true, //搜索框是否允许点击
      goodsEntity: [
        {
          picUrl:
            "//img.alicdn.com/imgextra/i1/2201297390627/O1CN01tM2mIw1GVCz4I2lqC_!!2201297390627.jpg",
          goodsName:
            "动力火车酒鸡尾酒套装洋酒调酒基酒混合300ml*24瓶整箱苏打酒正品（模板）",
          nick: "动力火车酒类旗舰店（模板）",
          price: "578.00（模板）",
        },
      ], //相关信息列表
      option: {
        //这里是echarts的相关数据
        title: {
          text: "竞品评价SKU占比分析",
          left: "left",
        },
        tooltip: {
          trigger: "item",
          transitionDuration: 0, //加上这个可以防止抖动
        },
        legend: {
          type: "scroll",
          orient: "vertical",
          x: "left", // 'center' | 'left' | {number},
          y: "top", // 'center' | 'bottom' | {number}
          top: "60px",
          left: "30px",
          textStyle: {
            lineHeight: 18,
          },
          formatter: (name) => {
            if (!name) return "";
            return this.getEqualNewlineString(name, 22); // 根据你的需求修改参数
          },
        },
        series: [
          {
            name: "访问来源",
            type: "pie",
            radius: "50%",
            center: ["65%", "50%"],
            avoidLabelOverlap: true,
            data: [
              {
                value: 11,
                name: "颜色分类：24瓶混合（诱惑+梦幻各12瓶）",
                itemStyle: {
                  color: "#21bf6b",
                },
              },
              {
                value: 3,
                name: "颜色分类：24瓶混合（冷艳+梦幻各12瓶）",
                itemStyle: {
                  color: "#fd5b65",
                },
              },
              {
                value: 24,
                name: "颜色分类：24瓶混合（诱惑+冷艳各12瓶）",
                itemStyle: {
                  color: "#fd9544",
                },
              },
              {
                value: 24,
                name: "颜色分类：24瓶混合（诱惑+冷艳各24瓶）",
                itemStyle: {
                  color: "#f9b732",
                },
              },
              {
                value: 162,
                name: "颜色分类：24瓶混合（纯情+冷艳+诱惑+狂野+浪漫+梦幻各4瓶）",
                itemStyle: {
                  color: "#4b7df8",
                },
              },
            ],
            label: {
              align: "left",
              normal: {
                formatter: (v) => {
                  let text = Math.round(v.percent) + "%" + "" + v.name;
                  return this.getEqualNewlineString(text, 16);
                },
              },
              rich: {
                a: {
                  color: "#333;",
                  fontWeight: 500,
                  lineHeight: 18,
                  fontSize: 12,
                  align: "right",
                },
              },
            },

            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      },
      color: [
        {
          color: "#4b7df8",
        },
        {
          color: "#f9b732",
        },
        {
          color: "#fd9544",
        },
        {
          color: "#21bf6b",
        },
        {
          color: "#fd5b65",
        },
        {
          color: "#8d4bbb",
        },
        {
          color: "#ef7a82",
        },
        {
          color: "#e9e7ef",
        },
        {
          color: "#75664d",
        },
        {
          color: "#161823",
        },
        {
          color: "#c91f37",
        },
        {
          color: "#3b2e7e",
        },
      ],
      introduce: [
        {
          title: "有什么作用？",
          icon: "&#xe60a;",
          content: [
            "通过SKU占比分析，我们可以得知竞品的热销SKU是什么，方便在考察新品的时候知道什么是重点主打款式，可以把热销SKU作为主图或创意图，提高点击率，还可分析SKU进货数量、分析竞品销售结构等等。",
          ],
        },
      ], //介绍相关数组
    };
  },
  components: {
    InputView,
    Introduce,
  },
  mounted() {
    this.initCharts();
  },
  methods: {
    //echarts的初始化
    initCharts() {
      let myChart = this.echarts.init(this.$refs.echarts);
      myChart.setOption(this.option);
    },
    // 点击搜索时的方法
    getSearchName(value) {
      this.value = value;
      this.$store.commit("alterJumpFlag", true);
      this.$store.commit(
        "alterLoadContent",
        "正在获取商品评价及竞品评价sku的数据"
      );
      this.CanYouClick = false; //不允许再点击搜索
      this.goodsSkuCountFn(value); //请求商品评价的数据
    },
    // 竞品评价sku占比分析
    goodsSkuCountFn(value) {
      // this.goodsEntity = [
      //   {
      //     picUrl: "",
      //     goodsName:"",
      //     nick: "",
      //     price: "",
      //   },
      // ]
      // this.option.data = []
      goodsSkuCount({
        itemid: value,
      })
        .then((data) => {
          this.goodsEntity = [data.data.goodsEntity];
          let newSkuMap = [];
          data.data.skuMap.setSkuCount.forEach((element, index) => {
            newSkuMap.push({
              value: element,
              name: data.data.skuMap.setSkuName[index],
              itemStyle: this.color[index],
            });
          });
          this.option.series[0].data = newSkuMap;
          this.initCharts();
        })
        .finally(() => {
          this.$store.commit("alterJumpFlag", false);
          this.CanYouClick = true; //允许再次搜索
        });
    },

    getEqualNewlineString(params, length) {
      let text = "";
      let count = Math.ceil(params.length / length); // 向上取整数
      // 一行展示length个
      if (count > 1) {
        for (let z = 1; z <= count; z++) {
          text += params.substr((z - 1) * length, length);
          if (z < count) {
            text += "\n";
          }
        }
      } else {
        text += params.substr(0, length);
      }
      return text;
    },
  },
};
</script>

<style lang="less" scoped>
.bannerContent {
  display: flex;
}
.img_floor {
  width: 100px;
  height: 100px;
  background: linear-gradient(0deg, #ceefff, #7bd5ff);
  margin-right: 50px;
}
</style>